import React from "react"
import { Link } from "gatsby"
import { PointH3, ContentLi, Bold, ContentP } from "./Style"

const OrderedList = ({ children, type }) => {
  return (
    <ol
      type={type}
      style={{
        paddingInlineStart: "20px",
        fontFamily: "Typefez-reg",
      }}
    >
      {children}
    </ol>
  )
}

const UnorderedList = ({ children }) => {
  return (
    <ul
      style={{
        paddingInlineStart: "20px",
        fontFamily: "Typefez-reg",
      }}
    >
      {children}
    </ul>
  )
}

const LinkHref = ({ link, children }) => {
  return (
    <a href={link} target="_blank">
      {children}
    </a>
  )
}

const ServiceAgreementPoint = ({ props, propsLink }) => {
  const {
    Article1,
    Article2,
    Article3,
    Article4,
    Article5,
    Article6,
    Article7,
  } = props
  return (
    <>
      <PointH3>{Article1.Title}</PointH3>
      <OrderedList type="i">
        <ContentLi>
          {Article1.Answer1.Desc1a}
          <Bold>{Article1.Answer1.Desc1b}</Bold>
          {Article1.Answer1.Desc1c}
        </ContentLi>
        <ContentLi>
          {Article1.Answer2.Desc1a}
          <Bold>{Article1.Answer2.Desc1b}</Bold>
          {Article1.Answer2.Desc1c}
          <Bold>{Article1.Answer2.Desc1d}</Bold>
          {Article1.Answer2.Desc1e}
          <Bold>{Article1.Answer2.Desc1f}</Bold>
          {Article1.Answer2.Desc1g}
          {Article1.Answer2.Desc1h}
          {Article1.Answer2.Desc1i}
          {Article1.Answer2.Desc1j}
          {Article1.Answer2.Desc1k}
        </ContentLi>
        <ContentLi>{Article1.Answer3.Desc1a}</ContentLi>
        <ContentLi>
          {Article1.Answer4.Desc1a}
          <Bold>{Article1.Answer4.Desc1b}</Bold>
          {Article1.Answer4.Desc1c}
        </ContentLi>
        <ContentLi>{Article1.Answer5.Desc1a}</ContentLi>
      </OrderedList>

      {/* ------------------------------------- */}

      <PointH3>{Article2.Title}</PointH3>
      <OrderedList type="i">
        <ContentLi>
          <Bold>{Article2.Answer1.Desc1a}</Bold>
          {Article2.Answer1.Desc1b}
        </ContentLi>
        <ContentLi>
          <Bold>{Article2.Answer2.Desc1a}</Bold>
          {Article2.Answer2.Desc1b}
        </ContentLi>
        <ContentLi>
          <Bold>{Article2.Answer3.Desc1a}</Bold>
          {Article2.Answer3.Desc1b}
        </ContentLi>
      </OrderedList>

      {/* ------------------------------------- */}

      <PointH3>{Article3.Title}</PointH3>
      <OrderedList type="i">
        <ContentLi>{Article3.Answer1.Desc1a}</ContentLi>
        <OrderedList type="a">
          <ContentLi>{Article3.Answer1.Desc1b}</ContentLi>
          <ContentLi>{Article3.Answer1.Desc1c}</ContentLi>
          <ContentLi>{Article3.Answer1.Desc1d}</ContentLi>
          <ContentLi>{Article3.Answer1.Desc1e}</ContentLi>
        </OrderedList>
        <ContentLi>{Article3.Answer2.Desc1a}</ContentLi>
        <OrderedList type="a">
          <ContentLi>{Article3.Answer2.Desc1b}</ContentLi>
          <ContentLi>{Article3.Answer2.Desc1c}</ContentLi>
          <ContentLi>{Article3.Answer2.Desc1d}</ContentLi>
          <ContentLi>{Article3.Answer2.Desc1e}</ContentLi>
          <ContentLi>{Article3.Answer2.Desc1f}</ContentLi>
          <ContentLi>{Article3.Answer2.Desc1g}</ContentLi>
          <ContentLi>{Article3.Answer2.Desc1h}</ContentLi>
        </OrderedList>
        <ContentLi>{Article3.Answer3.Desc1a}</ContentLi>
        <OrderedList type="a">
          <ContentLi>
            {Article3.Answer3.Desc1b} <Bold>{Article3.Answer3.Desc1c}</Bold>
            {Article3.Answer3.Desc1d}
          </ContentLi>
          <ContentLi>{Article3.Answer3.Desc1e}</ContentLi>
          <UnorderedList>
            <ContentLi>
              {Article3.Answer3.Desc1f}{" "}
              <LinkHref link={Article3.Answer3.Desc1link1}>
                {Article3.Answer3.Desc1g}
              </LinkHref>
            </ContentLi>
            <ContentLi>
              {Article3.Answer3.Desc1h}{" "}
              <LinkHref link={Article3.Answer3.Desc1link2}>
                {Article3.Answer3.Desc1i}
              </LinkHref>
              {Article3.Answer3.Desc1j}
            </ContentLi>
            <ContentLi>
              {Article3.Answer3.Desc1k}{" "}
              <LinkHref link={Article3.Answer3.Desc1link3}>
                {Article3.Answer3.Desc1l}
              </LinkHref>
              .
            </ContentLi>
          </UnorderedList>
          <ContentLi>{Article3.Answer3.Desc1m}</ContentLi>
          <ContentLi>{Article3.Answer3.Desc1n}</ContentLi>
          <ContentLi>{Article3.Answer3.Desc1o}</ContentLi>
        </OrderedList>
        <ContentLi>{Article3.Answer4.Desc1a}</ContentLi>
        <ContentP>{Article3.Answer4.Desc1b}</ContentP>
        <OrderedList type="a">
          <ContentLi>{Article3.Answer4.Desc1c}</ContentLi>
          <ContentP>{Article3.Answer4.Desc1d}</ContentP>
          <ContentP>{Article3.Answer4.Desc1e}</ContentP>
          <ContentP>{Article3.Answer4.Desc1f}</ContentP>
          <ContentP>{Article3.Answer4.Desc1g}</ContentP>
          <ContentP>{Article3.Answer4.Desc1h}</ContentP>
          <ContentLi>{Article3.Answer4.Desc1i}</ContentLi>
          <ContentP>{Article3.Answer4.Desc1j}</ContentP>
          <ContentP>{Article3.Answer4.Desc1k}</ContentP>
          <ContentP>{Article3.Answer4.Desc1l}</ContentP>
          <ContentP>{Article3.Answer4.Desc1m}</ContentP>
          <ContentP>{Article3.Answer4.Desc1n}</ContentP>
          <ContentP>{Article3.Answer4.Desc1o}</ContentP>
          <ContentP>{Article3.Answer4.Desc1p}</ContentP>
          <ContentP>{Article3.Answer4.Desc1q}</ContentP>
        </OrderedList>
        <ContentLi>{Article3.Answer5.Desc1a}</ContentLi>
        <OrderedList type="a">
          <ContentLi>{Article3.Answer5.Desc1b}</ContentLi>
          <ContentLi>{Article3.Answer5.Desc1c}</ContentLi>
        </OrderedList>
        <ContentLi>{Article3.Answer6.Desc1a}</ContentLi>
        <ContentP>{Article3.Answer6.Desc1b}</ContentP>
        <ContentLi>{Article3.Answer7.Desc1a}</ContentLi>
        <ContentP>{Article3.Answer7.Desc1b}</ContentP>
        <ContentLi>{Article3.Answer8.Desc1a}</ContentLi>
        <ContentP>{Article3.Answer8.Desc1b}</ContentP>
      </OrderedList>

      {/* ------------------------------------- */}

      <PointH3>{Article4.Title}</PointH3>
      <OrderedList type="i">
        <ContentLi>{Article4.Answer1.Desc1a}</ContentLi>
        <ContentLi>{Article4.Answer2.Desc1a}</ContentLi>
        <OrderedList type="a">
          <ContentLi>{Article4.Answer2.Desc1b}</ContentLi>
          <ContentLi>{Article4.Answer2.Desc1c}</ContentLi>
          <ContentLi>{Article4.Answer2.Desc1d}</ContentLi>
          <ContentLi>{Article4.Answer2.Desc1e}</ContentLi>
          <ContentLi>{Article4.Answer2.Desc1f}</ContentLi>
          <ContentLi>{Article4.Answer2.Desc1g}</ContentLi>
          <ContentLi>{Article4.Answer2.Desc1h}</ContentLi>
        </OrderedList>
        <ContentLi>
          {Article4.Answer3.Desc1a}
          <Bold>{Article4.Answer3.Desc1b}</Bold>
          {Article4.Answer3.Desc1c}
        </ContentLi>
        <ContentLi>{Article4.Answer4.Desc1a}</ContentLi>
        <OrderedList type="a">
          <ContentLi>{Article4.Answer4.Desc1b}</ContentLi>
          <ContentLi>{Article4.Answer4.Desc1c}</ContentLi>
        </OrderedList>
      </OrderedList>

      {/* ------------------------------------- */}

      <PointH3>{Article5.Title}</PointH3>
      <OrderedList type="i">
        <ContentLi>{Article5.Answer1.Desc1a}</ContentLi>
        <OrderedList type="a">
          <ContentLi>{Article5.Answer1.Desc1b}</ContentLi>
          <ContentLi>{Article5.Answer1.Desc1c}</ContentLi>
          <ContentLi>{Article5.Answer1.Desc1d}</ContentLi>
          <ContentLi>{Article5.Answer1.Desc1e}</ContentLi>
          <ContentLi>{Article5.Answer1.Desc1f}</ContentLi>
          <ContentLi>{Article5.Answer1.Desc1g}</ContentLi>
        </OrderedList>
        <ContentLi>{Article5.Answer2.Desc1a}</ContentLi>
        <OrderedList type="a">
          <ContentLi>{Article5.Answer2.Desc1b}</ContentLi>
          <ContentLi>{Article5.Answer2.Desc1c}</ContentLi>
          <ContentLi>{Article5.Answer2.Desc1d}</ContentLi>
          <ContentLi>{Article5.Answer2.Desc1e}</ContentLi>
        </OrderedList>
        <ContentLi>{Article5.Answer3.Desc1a}</ContentLi>
        <OrderedList type="a">
          <ContentLi>{Article5.Answer3.Desc1b}</ContentLi>
          <ContentLi>{Article5.Answer3.Desc1c}</ContentLi>
          <ContentLi>{Article5.Answer3.Desc1d}</ContentLi>
          <ContentLi>{Article5.Answer3.Desc1e}</ContentLi>
        </OrderedList>
      </OrderedList>

      {/* ------------------------------------- */}

      <PointH3>{Article6.Title}</PointH3>
      <OrderedList type="i">
        <ContentLi>{Article6.Answer1.Desc1a}</ContentLi>
        <ContentLi>{Article6.Answer2.Desc1a}</ContentLi>
      </OrderedList>

      {/* ------------------------------------- */}

      <PointH3>{Article7.Title}</PointH3>
      <OrderedList type="i">
        <ContentLi><Bold>{Article7.Answer1.Desc1a}</Bold></ContentLi>
        <ContentP>{Article7.Answer1.Desc1b}</ContentP>
        <ContentP>{Article7.Answer1.Desc1c}</ContentP>
        <ContentLi><Bold>{Article7.Answer2.Desc1a}</Bold></ContentLi>
        <ContentP>{Article7.Answer2.Desc1b}</ContentP>
        <ContentLi><Bold>{Article7.Answer3.Desc1a}</Bold></ContentLi>
        <ContentP>{Article7.Answer3.Desc1b}</ContentP>
        <ContentLi><Bold>{Article7.Answer4.Desc1a}</Bold></ContentLi>
        <ContentP>{Article7.Answer4.Desc1b}</ContentP>
        <ContentLi><Bold>{Article7.Answer5.Desc1a}</Bold></ContentLi>
        <ContentP>{Article7.Answer5.Desc1b}</ContentP>
        <ContentLi><Bold>{Article7.Answer6.Desc1a}</Bold></ContentLi>
        <ContentP>{Article7.Answer6.Desc1b}</ContentP>
        <OrderedList type="a">
          <ContentLi>{Article7.Answer6.Desc1c}</ContentLi>
          <ContentLi>{Article7.Answer6.Desc1d}</ContentLi>
          <ContentLi>{Article7.Answer6.Desc1e}</ContentLi>
          <ContentLi>{Article7.Answer6.Desc1f}</ContentLi>
          <ContentLi>{Article7.Answer6.Desc1g}</ContentLi>
        </OrderedList>
        <ContentLi><Bold>{Article7.Answer7.Desc1a}</Bold></ContentLi>
        <ContentP>
          {Article7.Answer7.Desc1b}{" "}
          <Link to="/en/privacy-policy">{Article7.Answer7.Desc1c}</Link>{" "}
          {Article7.Answer7.Desc1d}
        </ContentP>
        <ContentLi>{Article7.Answer8.Desc1a}</ContentLi>
        <ContentLi>{Article7.Answer9.Desc1a}</ContentLi>
        <ContentLi><Bold>{Article7.Answer10.Desc1a}</Bold></ContentLi>
        <ContentP>{Article7.Answer10.Desc1b}</ContentP>
        <ContentLi><Bold>{Article7.Answer11.Desc1a}</Bold></ContentLi>
        <ContentP>{Article7.Answer11.Desc1b}</ContentP>
        <ContentLi><Bold>{Article7.Answer12.Desc1a}</Bold></ContentLi>
        <ContentP>{Article7.Answer12.Desc1b}</ContentP>
        <ContentLi><Bold>{Article7.Answer13.Desc1a}</Bold></ContentLi>
        <ContentP>{Article7.Answer13.Desc1b}</ContentP>
      </OrderedList>
    </>
  )
}

export default ServiceAgreementPoint
