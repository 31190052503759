import React from "react"
import { Container } from "react-bootstrap"
import Point from "./Point"
import { SectionStyled, TitleContainer, TitleH1, MainDiv } from "./Style"

function SingaporeAgreement({ props, propsLink, status }) {
  return (
    <SectionStyled>
      <TitleContainer>
        <TitleH1>{props.Title}</TitleH1>
      </TitleContainer>
      <Container style={{ maxWidth: 800 }}>
        <MainDiv>
          <Point props={props} propsLink={propsLink} />
        </MainDiv>
      </Container>
    </SectionStyled>
  )
}

export default SingaporeAgreement
