import React from "react"
import "../layout.css"
import { useLocation } from "@reach/router"
import SingaporeAgreement from "../SingaporeServiceAgreement/Singapore"
import { NavigationBar } from "../NavigationBar/parent"
import Footer from "../FooterComponents/Footer"
import Point from './Point'

import {
  PathJson,
  SingaporeServiceAgreementJson,
  MainPageJson,
} from "../location"
import Seo from "../SEOComponents/Seo"
import { LayoutTextPage } from "../LayoutTextPage/parent"

function SingaporeServiceAgreement() {
  const MainPageContent = MainPageJson()
  const ServiceContent = SingaporeServiceAgreementJson()
  const PathContent = PathJson()
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")
  const { Title } = ServiceContent || {}

  return (
    <div className="maxwidth">
      <Seo title="sg-service-agreement" />
      <NavigationBar />
      <LayoutTextPage title={Title}>
        <Point props={ServiceContent} propsLink={PathContent} />
      </LayoutTextPage>
      <Footer props={MainPageContent.Footer} propsLink={PathContent} />
    </div>
  )
}

export default SingaporeServiceAgreement
